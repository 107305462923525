import React from 'react'
import ReactGA from "react-ga";

const Success = () => {
  ReactGA.pageview("Sale Success");
  return (
    <div>Success</div>
  )
}

export default Success