export const categories = [
  {
    id: 1,
    url: "https://www.instagram.com/p/CeRHj6GrydV",
    title: "OUTFITS",
    size: "1",
  },
  {
    id: 2,
    url: "https://www.instagram.com/reel/CeRIpt7KkKp/",
    title: "LOUNGEWEAR",
    size: "1",
  },
  {
    id: 3,
    url: "https://www.instagram.com/p/CeOnPhUI5UU/",
    title: "LIGHT JACKETS",
    size: "1",
  },
];

export const products0 = [
  {
    id: 1,
    url: "https://www.instagram.com/p/CfT07kdAk8J/",
    title: "435,678 kshs",
    size: "2",
  },
  {
    id: 2,
    url: "https://www.instagram.com/p/CfT0clPgzyZ/",
    title: "23 kshs",
    size: "2",
  },
  {
    id: 3,
    url: "https://www.instagram.com/p/CfTz6_igIZW/",
    title: "33,000 kshs",
    size: "2",
  },
  {
    id: 1,
    url: "https://www.instagram.com/p/CfTzZ3ZgdAV/",
    title: "500 kshs",
    size: "2",
  },
 
];


export const products = [
  {
    id: 1,
    url: "https://www.instagram.com/p/CeRHj6GrydV",
    title: "435,678 kshs",
    size: "2",
  },
  {
    id: 2,
    url: "https://www.instagram.com/reel/CeRIpt7KkKp/",
    title: "23 kshs",
    size: "2",
  },
  {
    id: 3,
    url: "https://www.instagram.com/p/CeOnPhUI5UU/",
    title: "33,000 kshs",
    size: "2",
  },
  {
    id: 1,
    url: "https://www.instagram.com/p/CeRHj6GrydV",
    title: "500 kshs",
    size: "2",
  },
  {
    id: 2,
    url: "https://www.instagram.com/reel/CeRIpt7KkKp/",
    title: "400 kshs",
    size: "2",
  },
  {
    id: 3,
    url: "https://www.instagram.com/p/CeOnPhUI5UU/",
    title: "3 kshs",
    size: "2",
  },
  {
    id: 1,
    url: "https://www.instagram.com/p/CeRHj6GrydV",
    title: "200 kshs",
    size: "2",
  },
  {
    id: 2,
    url: "https://www.instagram.com/reel/CeRIpt7KkKp/",
    title: "1 kshs",
    size: "2",
  },
  {
    id: 3,
    url: "https://www.instagram.com/p/CeOnPhUI5UU/",
    title: "10,000,000 kshs",
    size: "2",
  },
];

export const categoryNames = 
[
  {
    id: 0,
    name: "/",
  },
  {
    id: 1,
    name: "a",
  },
  {
    id: 2,
    name: "b",
  },
  {
    id: 3,
    name: "c",
  },
  {
    id: 4,
    name: "d",
  },
  {
    id: 5,
    name: "e",
  },
];


