import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AlternativeProducts from './Pages/AlternativeProducts';
import ReactGA from "react-ga";
import LoginandSignup from './Pages/LoginandSignup';
import '@stripe/stripe-js';
import Cancel from './Pages/Cancel';
import Success from './Pages/Success';
import Landing from './Pages/Landing';


function App() {
  const gaTrackingId = "UA-233033293-1"; 
  ReactGA.initialize(gaTrackingId);
  
  return (
    <Router>
      <Routes>
        <Route path='/' element={<AlternativeProducts/>} />
        <Route path='/Signup' element={<LoginandSignup/>} />
        <Route path='/FirstComment' element={<Landing/>} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
    </Routes>
    </Router>
  );
}

export default App;
/*
<Router>
    <NavBar></NavBar>
            <MobileNav></MobileNav>
    
    </Router>
*/

//https://www.artsy.net/article/artsy-editorial-6-art-historical-poses