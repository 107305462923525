import {React, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div
`
    height: fit-content;
    display:block;
    cursor: pointer;
    overflow:hidden;
    justify-content: center;
    align-items: center;
    @media (max-width: 1180px) {
        height: 140px;
    }
`;

const HideScrollContainer = styled.div
`
    width: 100vw;
    height: fit-content;
    overflow: hidden;
    @media (max-width: 1180px) {
        width: 100%;
        padding-top:4rem;
        height: 250px;
    }
    @media (max-width: 650px) {
        width: 100vw;
    }
`;


const LeftContainer = styled.div
`
    height: 100%;
    width: 100%;   
    justify-content: center;
    align-items: center; 
`;


const UpperText = styled.div
`
    width: 100%;
    height: 20%;
    justify-content: center;
    align-items: center;
    text-align:center;
    font-size:1.4rem;
    font-weight:900;
    @media (max-width: 1180px) {
        height: 10%;
        font-size:1rem;
    }
`;

const MidText = styled.div
`
    width: 100%;
    height: 25%;
    font-size: 1.4rem;
    text-align: center;
    @media (max-width: 1180px) {
        display: flex;
        position: relative;
        overflow: auto;
        font-size: 1rem;
    }
`;

const UpperTextItem = styled.a
`
    text-decoration: underline;
    text-align: left;
    padding-left: 15px;
    padding-top: 5px;
    @media (max-width: 1180px) {
      padding-left: 5px;
      padding-top: 5px;
    }
`;

const LowerText = styled.div
`
    width: 100%;
    height: 25%;
    font-size: 1rem;
    text-align: center;
    @media (max-width: 1180px) {
        height: 20%;
        display: flex;
        position: relative;
        overflow: auto;
    }
`;

const LowerTextItem = styled.a
`
padding-left: 5px;
padding-top: 10px;

    @media (max-width: 1180px) {
        padding-top: 5px;
    }
`;

const LowestText = styled.div
`
    width: 100%;
    height: 25%;
    font-size: 1rem;
    text-align: center;
    @media (max-width: 1180px) {
        height: 20%;
        display: flex;
        position: relative;
        overflow: auto;
    }
`;

const LowestTextItem = styled.a
`
padding-left: 5px;
padding-top: 10px;

    @media (max-width: 1180px) {
        text-align: left;
        padding-top: 5px;
    }
`;

const Category = styled.h1
`
  display: block;
  justify-content: center;
  text-align:center;
`;

const upperText = ['Dress', 'Nails', 'Makeup', 'Hair', 'Thrifts', 'Jewellery','Furniture'];
const lowerText = ['Food', 'Gossip', 'Memes', 'Cute', 'Travel', 'News','Politics', 'Cars','Jobs','Electronics', 'Animals'];
const lowestText = ['Men', 'Women', 'Hijabi', 'Health', 'Housing', 'Fashion','Music','Art'];


const AltNavbar = (props) => {
    const changeText = (text) => 
    { 
        props.setcurrentPage(0);
        props.setCategoryTitle(text);
        props.GetProducts(text,props.currentPage);
    };
    return (
      <HideScrollContainer>
    <Container >
        <LeftContainer>
            <UpperText>Categories</UpperText>
            <MidText>
            {upperText.map(item=>(
                <UpperTextItem onClick={() => changeText(item)}>{ item }</UpperTextItem>))}
            </MidText>
            <LowerText>
            {lowerText.map(item=>(
                <LowerTextItem onClick={() => changeText(item)}>{ item }</LowerTextItem>))}
            </LowerText>
            <LowestText>
            {lowestText.map(item=>(
                <LowestTextItem onClick={() => changeText(item)}>{ item }</LowestTextItem>))}
            </LowestText>
        </LeftContainer>
    </Container>
    <Category>{ props.categoryTitle }</Category>
    </HideScrollContainer>
  )
}

export default AltNavbar;
