import React from 'react';
import styled from 'styled-components';
import Products from '../AltCom/Products';
import Announcement from '../AltCom/Announcement';
import { products0} from "../Data/data.js";
import ReactGA from "react-ga";

const Container = styled.div
`
  display:flex;
`;

const Container0 = styled.div
`
  background-color: red;
  @media (max-width: 1180px) {
    width: 0%;
  }
`;

const Container1 = styled.div
`

justify-content: center;
  align-items: center;
  @media (max-width: 1180px) {
    width: 100%;
  }
`;

const Container2 = styled.div
`
  width: 0%;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 1180px) {
    width: 0%;
  }
`;

const AlternativeProducts = () => {
  ReactGA.pageview("Storefront Home");
  return (
    <Container>
        <Container0></Container0>
        <Container1>
          <Announcement></Announcement>
          <Products products = { products0 }></Products>
        </Container1>
        <Container2></Container2>
    </Container>
  )
}

export default AlternativeProducts;