import React from 'react';
import styled from 'styled-components';
import Tweet from "../Assets/Tweet.png"


const BlackContainer = styled.div
`
    height: 100%;
    width: 100;
    background-color: #111827;
    border-radius:25px;
    display:block;
    z-index:2;
    justify-content:top;
    align-items:top;
    @media (max-width: 1180px)
    {
        width:55%;
    }
    
`;

const TweetImage = styled.img
`
margin-top:10%;
margin-left:2.5%;
height:70%;
width: 95%;
object-fit:fill;
border-radius:25px;
@media (max-width: 1180px)
  {
    height:80%;
  }
`;

const WhiteContainer = styled.div
`
background-color:white;
margin-left:2.5%;
margin-top:-10%;
height:20%;
width: 95%;
`;

const TwitterScreen = () => {   
   
  return (
    <BlackContainer>
        <TweetImage src = {Tweet}></TweetImage>
        <WhiteContainer></WhiteContainer>
    </BlackContainer>
  )
}

export default TwitterScreen