import React from 'react';
import logovideo from "../Assets/logovideo.mp4";
import styled from 'styled-components';
import { GoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import {useNavigate} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import NewUpload from './NewUpload';
import axios from "axios";
import ReactGA from "react-ga";


const Container = styled.div
`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Container2 = styled.div
`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  background-color:black;
  opacity:0.7;
  
`;

const LogoContainer = styled.div
`
  position:absolute;
  z-index: 2;
  color: white;
  display:block; 
  top:50%;
  left: 20%;
`;

const Logo = styled.h1
`
  font-size : 2.5rem;
  text-align:center;
`;

const GoogleButton = styled.button
`
  justify-content: center;
  align-items: center;
  text-align:center;
  cursor:pointer;
  width: 200px;
  height: 40px;
  font-weight: bold;
  border-radius: 25px;
`;

const BackgroundVideo = styled.video
`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  WebkitMediaControls: 'display(none)' 
`;

const LoginandSignup = () => {
  const navigate = useNavigate();
  ReactGA.pageview("Storefront Login");
  const [showModal, setShowModal] = React.useState(false);
  const [userName, setuserName] = React.useState("");
  const [userEmail, setuserEmail] = React.useState("");
  const [freePosts, setfreePosts] = React.useState(0);
  const [updateKey, setUpdateKey] = React.useState(0);

  const responseGoogle = (response) => 
  {
    //localStorage.setItem('user', JSON.stringify(response.profileObj));
    var userDetails = jwt_decode(response.credential)
    if(userDetails)
    {
      setuserName(userDetails.name);
      setuserEmail(userDetails.email);
      const postData = {"name" :  userDetails.name, "email" : userDetails.email};
      var postLink = "https://fronstorebackend.herokuapp.com/api/user/login";
      axios.post(postLink, postData).then(response => 
      {
        if(response.data)
        {
          //console.log(response.data.freePosts);
          setfreePosts(response.data.freePosts);
          setShowModal(true);
          setUpdateKey(updateKey => updateKey + 1); 
        }
        else
        {
          navigate("/");
        }
      }).then(data => {}).catch(error => {navigate("/");console.log("Error: " + error);});
      
    }
    else
    {
      navigate("/");
      console.log("Did not work");
    }
    
   // navigate('/NewUpload', { replace: true });
  };
  return (
    <Container >
        <BackgroundVideo src = {logovideo} type = "video/mp4" loop = {true} controls = {false} autoPlay = {true} muted = {true} playsInline></BackgroundVideo>
        <Container2>
          <LogoContainer>
            <Logo>Social Storefront</Logo>
            <GoogleLogin
            
            render={(renderProps) => 
              (
              <GoogleButton 
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}> <FcGoogle/> Sign in with Google</GoogleButton>
            )}
            onSuccess={responseGoogle}
            onError={() => {console.log('Login Failed');}}
            cookiePolicy="single_host_origin"
            />            
          </LogoContainer>
        </Container2>
        {showModal && <NewUpload key = {updateKey} userEmail = {userEmail} freePosts = {freePosts} setfreePosts = {setfreePosts} />}
    </Container>
  )
}

export default LoginandSignup;