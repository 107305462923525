import React from 'react';
import styled from 'styled-components';
import { GoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import {useNavigate} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import axios from "axios";
import { FaCheckDouble, FaHeart,FaThumbsUp} from "react-icons/fa";
import Marquee from "react-fast-marquee";
import ReactGA from "react-ga";
import FirstNewUpload from './FirstNewUpload'; 
import TwitterScreen from '../AltCom/TwitterScreen';

const Container = styled.div
`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display:flex;
  background-color: #F9F8F0;
`;

const MainContainer = styled.div
`
    height: 100%;
    width: 80%;   
    justify-content: center;
    align-items: center; 
    display:block;
    background-color: #F9F8F0;
    @media (max-width: 1180px) 
    {
      width: 95%;
    }
`;

const SideContainer = styled.div
`
    height: 100%;
    width: 10%; 
    @media (max-width: 1180px) 
    {
      width: 2.55%;
    }
`;

const SubtitleContainer = styled.div
`
    display:flex;
`;
const Title = styled.h1
`
  color:teal;
  @media (max-width: 1180px)
  {
    
  }
`;
const Title2 = styled.h2
`
color:teal;
`;
const Title3 = styled.h3
`

`;

const ContentContainer = styled.div
`
  height: 75%;
  display:flex;
  @media (max-width: 1180px)
  {
    display:block;
  }
`;


const LeftContainer2 = styled.div
`
  height:100vh;
  width:60vh;
  border-radius: 25px;
  z-index:1;
  @media (max-width: 1180px)
  {
    display:flex;
    height:50%;
    width:100%;
    border-radius: 0px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    align-items:center;
    justify-content:center;
  }
`;

const RightContainer = styled.div
`
  background-color: teal;
  margin-left:1%;
  height:100%;
  width:59%;
  display:block;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  text-align:center;
  @media (max-width: 1180px)
  {
    display:block;
    height:fit-content;
    width:100%;
  }
`;

const LogoContainer = styled.div
`
  position:absolute;
  color: teal;
  display:block; 
  top:50%;
  left: 20%;
  @media (max-width: 1180px) 
  {
    top:15%;
    left: 20%;
  }
`;

const Logo = styled.h1
`
  font-size : 2.5rem;
  text-align:center;
  
`;

const GoogleButton = styled.button
`
  justify-content: center;
  align-items: center;
  text-align:center;
  cursor:pointer;
  width: 200px;
  height: 40px;
  font-weight: bold;
  border-radius: 25px;
`;

const CallToActionDiv = styled.div
`
justify-content: center;
  align-items: center;
  text-align:center;
  height: 40%;
  width: 100%;
  background-color: #F9F8F0;
  display: flex;

  @media (max-width: 1180px)
  {
    height: 30%;
  }
`;

const CheckBoxDiv = styled.div
`
  height: 60%;
  width: 100%;
  display: block;
`;

const CallTitle = styled.text
`
  font-size: 3.1rem;
  @media (max-width: 1180px)
  {
    font-size: 1.3rem;
  }
`;

const CallTitleOrange = styled.text
`
font-weight: bold;
text-decoration: orange wavy underline;
`;

const CallTitleOrangeGreen = styled.text
`
font-weight: bold;
color: teal;
`;


const CallSubtitle = styled.text
`
font-weight: bold;
text-decoration: teal wavy underline;
`;

const CheckBox = () => 
{
  const Container = styled.ul
  `
    height: 100%;
    width: 100%;
    display:block;
    justify-content: left;
    align-items: left;
    text-align:left;
    list-style: none;
  `;

  const InfoContainer = styled.li
  `
  &:before 
  {
    font-family: 'FontAwesome';
    color: orange;
    font-size: 1rem;
    margin-right: 5px; 
    margin-bottom: 2px; 
    content: "\f058";
    @media (max-width: 1180px)
    {
      margin-right: 3px; 
      margin-bottom: 2px;
    }
  }
    weight: 1;
    width: 100%;
  `;

  const CheckMark = styled(FaCheckDouble)
  `
  color: orange;
  font-size: 15px;
  margin-right: 5px; 
  margin-bottom: 2px; 
  `;

  const Heart = styled(FaHeart)
  `
  color: #FD1D1D;
  font-size: 15px;
  margin-right: 5px; 
  margin-bottom: 2px; 
  `;

  const ThumbUp = styled(FaThumbsUp)
  `
  color: #3b5998;
  font-size: 15px;
  margin-right: 5px; 
  margin-bottom: 2px; 
  `;
  const TextBox = styled.text
  `
  color: white;
  font-size: 21px;
  @media (max-width: 1180px)
  {
    font-size: 15px;
  }
  `;

  const OrangeText = styled.text
  `
  color: orange;
  font-size: 21px;
  @media (max-width: 1180px)
  {
    font-size: 16px;
  }
  `;

  const HighlightedText = styled.text
  `
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 21px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
  `;

  return(
    <Container>
      <InfoContainer >
        <TextBox>Be the <HighlightedText>FIRST</HighlightedText> to know <OrangeText>SECONDS</OrangeText>  after an account posts</TextBox>
      </InfoContainer>
      <InfoContainer>
        <TextBox>Leave the <HighlightedText>FIRST</HighlightedText> comment and receive <OrangeText>MILLIONS</OrangeText> of <Heart/> and <ThumbUp/> </TextBox>
      </InfoContainer>
      <InfoContainer>
        <TextBox>Advertise on Instagram, Twitter, YouTube, Linkedin, TikTok and Reddit comment sections for <OrangeText>FREE</OrangeText>  </TextBox>
      </InfoContainer>
      <InfoContainer>
        <TextBox>Comment on the posts of the <OrangeText>MOST ACTIVE</OrangeText> celebrities and influencers </TextBox>
      </InfoContainer>
      <InfoContainer>
        <TextBox>Get your opinions <OrangeText>NOTICED</OrangeText> by everyone </TextBox>
      </InfoContainer>
    </Container>
  );
};


const Google0 = styled(GoogleLogin)
`
visibility:hidden;
@media (max-width: 1180px)
  {
    visibility: visible;
  }
`;


const Google1 = styled(GoogleLogin)
`
@media (max-width: 1180px)
  {
    visibility: hidden;
  }
`;


const Landing = () => {
  const navigate = useNavigate();
  ReactGA.pageview("FirstComment Login");
  const [showModal, setShowModal] = React.useState(false);

  const [userName, setuserName] = React.useState("");
  const [userEmail, setuserEmail] = React.useState("");
  const [userPlan, setuserPlan] = React.useState("");
  const [userAvailableSpaces, setuserAvailableSpaces] = React.useState("");
  const [userphoneNumber, setuserphoneNumber] = React.useState("");
  const [usernotificationList, setusernotificationList] = React.useState([]);

  const [updateKey, setUpdateKey] = React.useState(0);
  const responseGoogle = (response) => 
  {
    var userDetails = jwt_decode(response.credential)
    if(userDetails)
    {
      setuserName(userDetails.name);
      setuserEmail(userDetails.email);
      const postData = {"name" :  userDetails.name, "email" : userDetails.email};
      var postLink = "https://fronstorebackend.herokuapp.com/api/firstuser/login";
      axios.post(postLink, postData).then(response => 
      {
        if(response.data)
        {
          setuserPlan(response.data.plan);
          setuserAvailableSpaces(response.data.availableSpaces);
          setuserphoneNumber(response.data.phoneNumber);
          setusernotificationList(response.data.notificationList);

          setShowModal(true);
          setUpdateKey(updateKey => updateKey + 1); 
          //console.log(response.data);
        }
        else
        {
          navigate("/landing");
        }
      }).then(data => {}).catch(error => {navigate("/landing"); console.log("Error: " + error);});
      
    }
    else
    {
      navigate("/landing");
      console.log("Did not work");
    }
    };
  return (
    <Container>
        <SideContainer/>
        <MainContainer>
          <Title>First Comment</Title>
          <SubtitleContainer>
            <Title3>by</Title3>
            <Title2>&nbsp; Social Storefront</Title2>
          </SubtitleContainer>
          <ContentContainer>
            <LeftContainer2>
              <TwitterScreen/>  
            </LeftContainer2>
            <RightContainer>
              <CallToActionDiv>
                <CallTitle>
                <CallTitle>Be the <CallTitleOrange>first</CallTitleOrange> to get your <CallSubtitle> <br/>Comments Seen</CallSubtitle><br/> by <CallTitleOrangeGreen>millions</CallTitleOrangeGreen> on social media</CallTitle>
                </CallTitle>
              </CallToActionDiv>
              <Google1
            size = {"large"}
            render={(renderProps) => 
              (
              <GoogleButton 
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}> <FcGoogle/> Sign in with Google</GoogleButton>
            )}
            onSuccess={responseGoogle}
            onError={() => {console.log('Login Failed');}}
            cookiePolicy="single_host_origin"
            />  
              <CheckBoxDiv>
                <CheckBox></CheckBox>
              </CheckBoxDiv>
              
            </RightContainer>
          </ContentContainer>
        </MainContainer>
        <SideContainer/>
        {showModal && <FirstNewUpload key = {updateKey} userName = {userName} userEmail = {userEmail} plan = {userPlan} availableSpaces = {userAvailableSpaces} phoneNumber = {userphoneNumber} notificationList = {usernotificationList} setusernotificationList = {setusernotificationList}/>}

    </Container>
  )
}

export default Landing;
/*
<LeftContainer>
            <Marquee>
  "My Twitter blows up whenever I comment first on Elon Musk's tweets" - Katy Coleman.
  "I run a Addison Rae stan account and it helps whenever I quickly repost her Instagram pics" - Sarah McFarland.
  "I'm Democrat and I love shitposting on Republican tweets. I guess this works because I catch the tweets really early" - Josie Kinger.
            </Marquee>
            <LogoContainer>
            <Logo>First Comment</Logo>
            <GoogleLogin
            
            render={(renderProps) => 
              (
              <GoogleButton 
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}> <FcGoogle/> Sign in with Google</GoogleButton>
            )}
            onSuccess={responseGoogle}
            onError={() => {console.log('Login Failed');}}
            cookiePolicy="single_host_origin"
            />            
          </LogoContainer>
            </LeftContainer>
*/