import React from 'react'
import ReactGA from "react-ga";

const Cancel = () => {
  ReactGA.pageview("Sale Cancel");
  return (
    <div>Cancel</div>
  )
}

export default Cancel