import { orange } from '@mui/material/colors';
import React from 'react'
import styled from 'styled-components';

const PriceCard = styled.div
`
display: block;
border-radius: 15px;
height: fit-content;
width: 33%;
background-color: white;
justify-content: space-between;
opacity: 1;
box-shadow: 0 8px 14px -6px #a0c5fa;
  transition: 0.4s ease-in-out;
  &:hover {
    box-shadow: 0 8px 26px -6px #a0c5fa;
    margin-bottom: 0.4rem;
  }
`;

const PriceCardTitle = styled.div
`
    margin: 3px;
    height: 20px;
    background-color: #ebf3fd;
    background-image: ${(props) => props.background};
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
`;
const PricingContainer = styled.div`
  height: 30%;
  display: flex;
  
  flex-direction: column;
  margin-top: 3rem;
`;


const CurrencyContainer = styled.div
`
  margin-right: 10px;
  margin-left: 10px;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  text-align:center;
  @media (max-width: 1180px) {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 18px;
  }
`;


const Button = styled.button
`
  margin-bottom:auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: 0.1rem solid #1d3557;
  width: 100%;
  height: 50px;
  background-color: transparent;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: #1d3557;
    color: white;
  }
  @media (max-width: 1180px) {
    font-size: 15px;
    height: 50px;
  }
`;

const DetailsContainer = styled.ul
`
  display: block;
  margin-top: 3px;
  margin-left: 10px;
  padding: 0px;
`;

const SingleDetail = styled.li
`
margin-left:0px;

`;

const SmallDetail = styled.li
`
font-size: 15px;

`;




const SinglePriceCard = (props) => {
    
  return (
    <PriceCard>
        <PriceCardTitle background = {props.background}>{props.title}</PriceCardTitle>
        <CurrencyContainer>{props.price}</CurrencyContainer>
        <DetailsContainer>
            <SingleDetail>{props.freeMonthlyListing} posts/mo</SingleDetail>
            {(props.first) && <SmallDetail>{props.first}</SmallDetail>}
            {(props.second) && <SmallDetail>{props.second}</SmallDetail>}
            {(props.third) && <SmallDetail>{props.third}</SmallDetail>}
            {(props.fourth) && <SmallDetail>{props.fourth}</SmallDetail>}
            {(props.fifth) && <SmallDetail>{props.fifth}</SmallDetail>}
            {(props.sixth) && <SmallDetail>{props.sixth}</SmallDetail>}
        </DetailsContainer>
        {(props.click) && <Button onClick = {props.click}>Get Started</Button>}
    </PriceCard>
  )
}

export default SinglePriceCard