import React from 'react';
import styled from 'styled-components';
import { InstagramEmbed } from 'react-social-media-embed';

const Container = styled.div
`
    transform: scale(1.0);
    object-fit: cover;
    position: relative;
    cursor: pointer;
    background-color: #f5fbfd;
    justify-content: center;
    height: max-content;
    padding: 20px;
`;


const Information = styled.div
`
    position: absolute;
    width: max-content;
    height: 10%;
    display:flex;
    flex-direction: column;
    align-items: right;
    justify-content:right;
    background-color: black;
    opacity: 0.5;
    bottom: 32.5%;
    right:5.5%;
`;


const Title = styled.h1
`
    color: white;
    font-size: 22.5px;
    font-weight: 900;
    text-align:right;
`;

export const PriceProduct = (props) => {
    console.log(props.links);
  return (
    <Container>
        <InstagramEmbed url={ props.item.url }  width = {350} height = {700} retryDisabled = { true }/>
        <Information>
            <Title>Click to list your business for FREE!</Title>
        </Information>
    </Container>
  )
}

export const PriceProductLinks = (props) => {
    console.log(props.key);
  return (
    <Container>
        <InstagramEmbed url={ props.item }  width = {350} height = {700} retryDisabled = { true }/>
        <Information>
        </Information>
    </Container>
  )
}

export default PriceProduct;