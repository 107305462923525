import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import Select from "react-select";
import { useFormik, ErrorMessage, FieldArray,Formik, Field} from "formik";
import {useNavigate} from 'react-router-dom';
import FirstSinglePriceCard from '../AltCom/FirstSinglePriceCard';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import ReactGA from "react-ga";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css'

const Container = styled.div
`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display:flex;
  background-color: white;
  z-index:3;
  opacity:0.95;
  @media (max-width: 760px) {
    height: fit-content;
  }
`;

const SideContainer = styled.div
`
    height: 100%;
    width: 20%; 
    opacity: 0;  
    background-color: transparent;
    @media (max-width: 760px) {
        width: 2.55%;
      }
`;

const MainContainer = styled.div
`
    height: 100%;
    width: 60%;   
    justify-content: center;
    align-items: center; 
    display:block;
    @media (max-width: 760px) {
        width: 95%;
      }
`;

const Title = styled.label
`
    font-size: 15px;
    height: 18px;
    text-align:center;
    font-weight: 700;
    padding: 2px;
`;
const Form = styled.form
`
padding-top:0px;
margin-top:0;
`;


const Input = styled.input
`
`;

const Button = styled.button
`
justify-content: center;
align-items: center;
text-align:center;
cursor:pointer;
width: fit-content;
height: 30px;
font-weight: bold;
border-radius: 5px;
margin-top: 7.5px;
margin-left: 10px;
`;


const entireList = 
[
"Twitter", "Instagram"
];

const EmailText = styled.p
`
visibility: ;
`;

const TitleText = styled.h2
`
    text-decoration:underline;
    font-size: 25px;
    text-alignment: center;
`;

const ExitButton = styled.button
`
`;

const PriceContainer = styled.div
`
display:flex;
justify-content: space-between;
`;

const ChoiceContainer = styled.div
`
display:block;
height:200px;
overflow-y: auto;
`;

const NotificationsContainer = styled.div
    `
    width: 100%;
    height: fit-content;
    display:flex;
    background-color: white;
    flex-wrap: wrap;
    `;
const AccountTitle = styled.h1
`
    font-size: 20px;
    justify-content: center;
    align-items: center; 
    text-align:center;
    font-weight: 700;
    margin-top:10px;
    margin-right:15px;
    margin-left:5px;
    @media (max-width: 760px)
    {
        font-size: 15px;
        margin-right:0px;
        margin-left:0px;
        margin-top:17px;
    }
`;

const LabelContainer = styled.div
`
display:flex;
height:fit-content;
`;
const SocialLabel = styled.label
`
margin-left:60px;
@media (max-width: 780px)
{
    margin-left:20px;
}

`;
const UsernameLabel = styled.label
`
margin-left:60px;
`;
const SelectBox = styled(Select)
`
height: 45px;
width:20%;
margin-left:5px;
margin-top:5px;
@media (max-width: 760px)
    {
        width:30%;
    }
`;
const InputBox = styled(Field)
`
height: 40px;
margin-left:5px;
border-radius:5px;
margin-top:5px;
`;

const PhoneInputStyled= styled(PhoneInput)
`
height: fit-content;
width: 100px;
margin-top: 20px;
margin-left: 10px;`;


let stripePromise;
const CreateStripePromise = () =>
{
    if(!stripePromise)
    {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    }
    return stripePromise;
}

const FirstNewUpload = (props) => {
    ReactGA.pageview("Storefront New Upload");
    const [phoneNumber, setphoneNumber] = React.useState("");
    const starterPriceID = 'price_1LPOTqBHcru1xstkAZaZnbOs';
    const premiumPriceID = 'price_1LPOU9BHcru1xstkJ4tyPpWS';
    var totalLength = 0;
    totalLength = parseInt(props.availableSpaces);
    var choices = Array(totalLength).fill("");
    const checkoutOptions = 
    {
        mode: 'subscription',
        lineItems: 
        [
            {
                price: "",
                quantity: 1,
            }
        ],
        successUrl: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/cancel`
    };

    async function Checkout(priceId) 
    {
        checkoutOptions.lineItems[0].price = priceId;
        const stripe = await CreateStripePromise();
        const {error} = await stripe.redirectToCheckout(checkoutOptions);
    };

    const categoryList = [];
    const navigate = useNavigate();
    for(var i = 0; i < entireList.length; i++)
    {
        categoryList.push({"label":entireList[i], "value":entireList[i]});
    }

    var initialValues1 = [];
    for(let i = 0; i < props.notificationList.length; i++)
    {
        initialValues1.push({"socialMedia":props.notificationList[i].socialMedia,"userNames":props.notificationList[i].userName});
    }
    console.log(initialValues1);
    const initialValues = 
    {
        users: (initialValues1.length > 0) ?
        initialValues1
        :
        [{
            socialMedia:'',
            userNames:'' 
        }]
    };
    const onSubmit = async (values, formikProps) => {
        //idk what this does
      };
    
  
  
    function HandleExitClick() {
        navigate("/landing");
      }
    const options = [
        { value: 'Twitter', label: 'Twitter' },
        { value: 'Instagram', label: 'Instagram' }
      ];
    function HandleSubmission(values)
    {
        console.log(values);
        var postData = 
        {
            "email" : props.userEmail,
            "users" : values.users
        };
        var postLink = "https://fronstorebackend.herokuapp.com/api/firstuser/updatelist";
        axios.post(postLink, postData).then(response => 
        {
            if(response.data)
            {
                //props.setusernotificationList(response.data.notificationList);
                console.log(response.data);
            }
            else
            {
                //navigate("/landing");
            }
        }).then(data => {}).catch(error => {console.log("Error: " + error);});
            
    }

    function GetDefaultValue(index)
    {
        console.log(props.notificationList[index].socialMedia);
        if(!props.notificationList)
        {
            return null;
        }
        else if(props.notificationList[index].socialMedia === "Twitter")
        {
            return options[0];
        }
        else
        {
            return options[1];
        } 
    }

  
  return (
    <Container>
        <SideContainer/>
        <MainContainer>
            <TitleText onClick = {() => Checkout(starterPriceID)}>Notification Settings</TitleText>
            <Title>Add account</Title> 
            <LabelContainer>
                <SocialLabel>Social Media</SocialLabel>
                <UsernameLabel>Username</UsernameLabel>
            </LabelContainer>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                >
                {({ values,setFieldValue,handleChange }) => (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        HandleSubmission(values);
                    }}>
                    <ChoiceContainer>
                    <FieldArray name="users">
                        {({ insert, remove, push }) => (
                        <div>
                            {totalLength > 0 &&
                            choices.map((item,index)=>(
                                <NotificationsContainer>
                                    <AccountTitle>{index + 1} </AccountTitle>
                                    <SelectBox 
                                    name = {`users.${index}.socialMedia`} 
                                    defaultValue={GetDefaultValue(index)}
                                    options = {options} 
                                    onChange = {(option, error) => {
                                    console.log(option);
                                    setFieldValue(`users.${index}.socialMedia`, option.value);
                                    }} />
                                    <InputBox 
                                        name = {`users.${index}.userNames`}
                                        placeholder="Enter username"
                                        onChange={handleChange}
                                        type="text">
                                    </InputBox>
                                </NotificationsContainer>
                                )
                            )}
                        </div>
                        )}
                    </FieldArray>
                    </ChoiceContainer>
                    <EmailText>You will receive social media notifications for these accounts </EmailText>
                    <Button type="submit">Add/Update notifications</Button>
                </Form>
                )}
            </Formik>
            <PhoneInputStyled
                name="phoneNumber"
                value = {phoneNumber}
                onChange = {(value, country, event, formattedValue) => {setphoneNumber(formattedValue)}}
                enableAreaCodes={true}
                defaultCountry="us"
                />
            <Button>Update Whatsapp number</Button>
            <PriceContainer>
                <FirstSinglePriceCard first  = {"Notifications sent to Email or Whatsapp"} freeMonthlyListing = {"3 account tracking notifications"} background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)" title = {"Free"}  price = "$ 0 / month"/>
                <FirstSinglePriceCard click = {()=>Checkout(starterPriceID)} first  = {"Notifications sent to Email and Whatsapp"} third = "AI generated replies" fourth = "Social media manager" freeMonthlyListing = {"15"} background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);" title = {"Starter"} price = "$ 5 / month"/>
                <FirstSinglePriceCard click = {()=>Checkout(premiumPriceID)} first  = {"Notifications sent to Email and Whatsapp"} third = "Professional Replies" fourth = "Social media manager" fifth = "Personal Commenting Bot created for business" sixth = "Professional bot management" freeMonthlyListing = {"60"} background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);" title = {"Premium"} price = "$ 20 / month"/>
            </PriceContainer>
            
            <ExitButton onClick={HandleExitClick}>Exit Registration</ExitButton>
        </MainContainer>
    </Container>
   
  )
}

export default FirstNewUpload;
