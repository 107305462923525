import React, { useEffect } from 'react';
import styled from 'styled-components';
import Select from "react-select";
import { useFormik } from "formik";
import {useNavigate} from 'react-router-dom';
import SinglePriceCard from '../AltCom/SinglePriceCard';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import ReactGA from "react-ga";


const Container = styled.div
`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display:flex;
  background-color: white;
  z-index:3;
  opacity:0.8;
`;

const SideContainer = styled.div
`
    height: 100%;
    width: 20%; 
    opacity: 0;  
    background-color: transparent;
    @media (max-width: 1180px) {
        width: 2.55%;
      }
`;

const MainContainer = styled.div
`
    height: 100%;
    width: 60%;   
    justify-content: center;
    align-items: center; 
    display:block;
    @media (max-width: 1180px) {
        width: 95%;
      }
`;

const Title = styled.label
`
    font-size: 15px;
    height: 18px;
    text-align:center;
    font-weight: 700;
    padding: 2px;
`;
const Form = styled.form
`
padding-top:0px;
margin-top:0;
`;


const Input = styled.input
`
`;

const Button = styled.button
`
justify-content: center;
align-items: center;
text-align:center;
cursor:pointer;
width: fit-content;
height: 30px;
font-weight: bold;
border-radius: 5px;
`;


const entireList = 
[
'Dress', 'Nails', 'Makeup', 'Hair', 'Thrifts', 'Jewellery','Furniture',
'Food', 'Gossip', 'Memes', 'Cute', 'Travel', 'News','Politics', 'Cars','Jobs','Electronics', 'Animals',
'Men', 'Women', 'Hijabi', 'Health', 'Housing', 'Fashion','Music','Art','Others'
];

const EmailText = styled.p
`
visibility: ;
`;

const TitleText = styled.h2
`
    text-decoration:underline;
    font-size: 25px;
    text-alignment: center;
`;

const ExitButton = styled.button
`
`;

const PriceContainer = styled.div
`
display:flex;
justify-content: space-between;
`;


const ValidateFields = values => 
{console.log('Here');
    const errors = {};
    if(!values.country) 
    { console.log('Here');
        errors.country = 'Required';
    } 
    if(!values.state) 
    { 
        errors.state = 'Required';
    } 
    if(!values.city) 
    { 
        errors.city = 'Required';
    } 
    if(!values.category) 
    { 
        errors.category = 'Required';
    } 
    if(!values.instagramLink) 
    { 
        errors.instagramLink = 'Required';
    } 
    else if(values.instagramLink.length > 15)
    {
        errors.instagramLink = 'Must be 15 characters or less';
    }
    return errors;
};

let stripePromise;
const CreateStripePromise = () =>
{
    if(!stripePromise)
    {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    }
    return stripePromise;
}

const NewUpload = (props) => {
    ReactGA.pageview("Storefront New Upload");
    const starterPriceID = 'price_1LIVcPBHcru1xstkLqPjOpIY';
    const premiumPriceID = 'price_1LIVcDBHcru1xstkjVxdhkEu';

    const checkoutOptions = 
    {
        mode: 'subscription',
        lineItems: 
        [
            {
                price: "",
                quantity: 1,
            }
        ],
        successUrl: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/cancel`
    };

    async function Checkout(priceId) 
    {
        checkoutOptions.lineItems[0].price = priceId;
        const stripe = await CreateStripePromise();
        const {error} = await stripe.redirectToCheckout(checkoutOptions);
    };

    const categoryList = [];
    const navigate = useNavigate();
    for(var i = 0; i < entireList.length; i++)
    {
        categoryList.push({"label":entireList[i], "value":entireList[i]});
    }
    const formik = useFormik({
        initialValues: { 
            country: "",
            state: "",
            city: "",
            category: "",
            instagramLink:"",
            
        },
        ValidateFields,
        onSubmit: values => 
        {
            const postData = 
            {
                "email" :props.userEmail,
                "country" : values.country.label,
                "state" : values.state.label,
                "city":  values.city.label,
                "category": values.category.label,
                "instagramLink":values.instagramLink,
            };
            console.log(postData);
            var postLink = 'https://fronstorebackend.herokuapp.com/api/user/submit'
            axios.post(postLink, postData).then(response => 
            {
                if(response.data)
                {
                    props.setfreePosts(response.data.value);
                }
                else
                {
                    console.log("No data");
                }
            }).then(data => {}).catch(error => {console.log("Error: " + error);});
        }
      });
   
    
    
   
    const { values, handleSubmit, setFieldValue, setValues } = formik;
    useEffect(() => {}, [values]);
    function HandleExitClick() {
        navigate("/");
      }
  return (
    <Container>
        <SideContainer/>
        <MainContainer>
            <TitleText onClick = {() => Checkout(starterPriceID)}>Register Online Business </TitleText>
            <Form onSubmit={formik.handleSubmit}>
            <Title>Country</Title>    
            <Select id="country" name="country" label="country" value={values.country}
                    onChange={(value) => {setValues({ country: value, state: "", city: "" });}}/>
            {formik.errors.country ? <div>{formik.errors.country}</div> : null}
            <Title>State</Title>  
            <Select id="state" name="state" label="state" value={values.state}
                    onChange={(value) => setFieldValue("state", value)}/>
            <Title>City</Title>  
            <Select id="city" name="city" label="city"  value={values.city}
                    onChange={(value) => setFieldValue("city", value)}/>
            
            <Title>Category</Title>  
            <Select id="category" name="category" label="category" options={categoryList} value={values.category}
                    onChange={(value) => setFieldValue("category", value)}/>
            <Title htmlFor="link">Instagram Username (Public account)</Title>
            <Input
                type="text"
                id = "instagramLink"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.instagramLink}
            />
            <Button type="submit">Submit</Button>
            </Form>
            <EmailText>You have {props.freePosts} submissions left</EmailText>
            <PriceContainer>
                <SinglePriceCard first  = {"AI generated images for Instagram"} second = "Free job listings" freeMonthlyListing = {"3"} background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)" title = {"Free"}  price = "$ 0 / month"></SinglePriceCard>
                <SinglePriceCard click = {()=>Checkout(starterPriceID)} first  = {"Professional images/video for Insta "} third = "Free job listings" fourth = "Social media manager" freeMonthlyListing = {"10"} background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);" title = {"Starter"} price = "$ 5 / month"/>
                <SinglePriceCard click = {()=>Checkout(premiumPriceID)} first  = {"Professional images/video for Insta"} third = "Free job listings" fourth = "Social media manager" fifth = "Personal Crypto token created for Web3 business" sixth = "Token listed on exchange" freeMonthlyListing = {"25"} background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);" title = {"Premium"} price = "$ 20 / month"/>
            </PriceContainer>
            
            <ExitButton onClick={HandleExitClick}>Exit Registration</ExitButton>
        </MainContainer>
    </Container>
   
  )
}

export default NewUpload;