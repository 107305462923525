import styled from '@emotion/styled';
import React from 'react';
import {useNavigate} from 'react-router-dom';


const Container = styled.div
`
    height: 40px;
    background-color: teal;
    color: white;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content:center;
    font-size: 18px;
    font-weight:500;
    @media (max-width: 760px) {
      height: 30px;
    }
`;

const Container2 = styled.div
`
    height: 40px;
    background-color: teal;
    color: white;
    display: flex;
    align-items: center;
    justify-content:center;
    font-size: 18px;
    font-weight:500;
    text-decoration: underline;
    @media (max-width: 760px) {
      height: 30px;
      font-size: http://localhost:3000/px;
    }
`;


const Announcement = () => {
  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate('/Signup');
  };
  return (
    <Container onClick={navigateToSignup}>1000s of buyers. Click here to register...
      <Container2> for FREE</Container2>
    </Container>
  );
}

export default Announcement;