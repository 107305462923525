import {React , useState, useEffect} from 'react';
import {PriceProduct, PriceProductLinks} from './ProductItem.jsx';
import styled from 'styled-components';
import AltNavbar from '../AltCom/AltNavbar';
import ReactPaginate from 'react-paginate';
import axios from "axios";
import {useNavigate} from 'react-router-dom';



const Grid = styled.div
`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const Container = styled.div
`
  display:flex;
  justify-content: center;
  width: 100%;
`;

const Container0 = styled.div
`
  flex: 1;
`;

const GridOutside = styled.div
`
  display: block;
  flex: 2;
  
`;



const Container2 = styled.div
`
  flex: 1;
`;

const RightContainer = styled.div
`
    height: 0px;
    width: 15%;
    display:block;
    text-align: center;
    @media (max-width: 1180px) {
      height: 10px;
      }
`;

const Logo = styled.h1
`
  font-weight: bold;
  font-size: 1.5rem;
  @media (max-width: 1180px) {
    font-size: 1rem;
    margin-left: 10px;
    padding-left: 10px;

  }
`;

const Sublogo = styled.h1
`
  font-weight: bold;
  font-size: 1rem;
  @media (max-width: 1180px) {
    font-size: 0.5rem;
    margin-left: 10px;
    padding-left: 10px;
  }
`;

const SubSubLogo = styled.h1
`
  font-weight: bold;
  font-size: 1.5rem;
  @media (max-width: 1180px) {
    font-size: 1rem;
    margin-left: 10px;
    padding-left: 10px;
  }
`;

const RegisterButton = styled.button
`
justify-content: center;
align-items: center;
text-align:center;
cursor:pointer;
width: fit-content;
height: 30px;
font-weight: bold;
border-radius: 5px;
margin: 0 auto;
display: block;
`;

//https://stackoverflow.com/questions/56784954/state-returning-empty-array
const Products = ({products}) => {
  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate('/Signup');
  };
  const elementCount = 4;
  const [newLoad, setNewLoad] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [items, setItems] = useState();
  const [updateKey, setUpdateKey] = useState(0);
  const [categoryTitle, setCategoryTitle] = useState("Dress"); 
  const GetProducts = async (pageCategory,pageIndex) =>
  {
    var currentIndex = 0;
    if(pageCategory === "Dress"){currentIndex = 0}
    else if(pageCategory === "Nails"){currentIndex = 1}
    else if(pageCategory === "Makeup"){currentIndex = 2}
    else if(pageCategory === "Hair"){currentIndex = 3}
    else if(pageCategory === "Thrifts"){currentIndex = 4}
    else if(pageCategory === "Jewellery"){currentIndex = 5}
    else if(pageCategory === "Furniture"){currentIndex = 6}
    else{currentIndex = 7}
    setNewLoad(currentIndex);
    const postData = {"locationName" : "New York", "startIndex" : JSON.stringify(pageIndex*elementCount),"elementCount" : JSON.stringify(elementCount), "index" : JSON.stringify(currentIndex)};
    var postLink = 'https://fronstorebackend.herokuapp.com/api/newCategory/getLinks'
    axios.post(postLink, postData)
    .then(response => 
      {
        if(response.data)
        {
          setItems(response.data);
        }
        else
        {
          //console.log("Unable to set");
        }
      }).then(data => {setUpdateKey(updateKey => updateKey + 1);})
    .catch(error => 
      {
        //console.log("Error: " + error);
      });
  }

  useEffect(() =>{
    GetProducts("Dress",0);
  },[])

  const handleClick = (clickData)=>{
    //console.log(clickData.selected + 1);
    //console.log("Category: ", categoryTitle, " Index: ", clickData.selected + 1);
    setcurrentPage(clickData.selected);
    GetProducts(categoryTitle,clickData.selected);//) 0-indexing in Mongo, remember to encode as json
  }
  return (
    <Container>
      <Container0>
      </Container0>
          <GridOutside>
            <RightContainer>
              <Logo>StoreFront</Logo>
              <Sublogo>for</Sublogo>
              <SubSubLogo>Instagram</SubSubLogo>
            </RightContainer>
            <AltNavbar currentPage = {currentPage} GetProducts = {GetProducts} setcurrentPage = {setcurrentPage} categoryTitle = { categoryTitle } setCategoryTitle = { setCategoryTitle } ></AltNavbar>
            <ReactPaginate
            previousLabel = {'<<'}
            nextLabel = {'>>'}
            breakLabel = {'...'}
            pageCount = {6}
            forcePage = {currentPage}
            marginPagesDisplayed = {3}
            pageRangeDisplayed = {3}
            onPageChange = {handleClick}
            containerClassName = {'pagination justify-content-center'}
            pageClassName = {'page-item'}
            pageLinkClassName = {'page-link'}
            previousClassName = {'page-item'}
            previousLinkClassName = {'page-link'}
            nextClassName = {'page-item'}
            nextLinkClassName = {'page-link'}
            breakClassName = {'page-item'}
            breakLinkClassName = {'page-link'}
            activeClassName = {'active'}
            />
            <RegisterButton onClick={navigateToSignup}>Click to Register for Free</RegisterButton>
            <Grid>
              {(items && items.length && items.length > 0 && newLoad < 7) ?
              ( 
                //console.log(items[0])
                <ProductsWrapper products={items} key = {updateKey}></ProductsWrapper>
              ) 
              : 
              (
                products.map(item=>(<PriceProduct item = { item } ></PriceProduct>))
              )}
            </Grid>
            
            
          </GridOutside>
      <Container2></Container2>
    </Container>
  )
}

const ProductsWrapper  = ({products}) => 
{
  return products.map(item=>(<PriceProductLinks item = { item } ></PriceProductLinks>))
}


export default Products;